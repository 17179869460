.card-header[data-bs-toggle=collapse]{
    position: relative;
    padding-right: 3rem;
    overflow: hidden;
    &::after{
        font-weight:normal;
        content: "\e5cf";
        font-family: "Material Symbols Rounded";
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        position: absolute;
        right: 1rem;
        top: 50%;
        font-size: 1.25rem;
        margin-top: -1rem;
        transition: transform cubic-bezier(0.215, 0.610, 0.355, 1) .25s;
    }
    &[aria-expanded=true]{
        &::after{
            transform: rotate(180deg);
        }
    }
}
.collapse-text{
    display: inline-block;
    cursor: pointer;
    &::after{
        content: attr(data-text);
    }
    &[aria-expanded=true]{
        &::after{
            content:"show less"
        }
    }
}