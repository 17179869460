//Back to top button style
.toTop{
    position: relative;
    opacity: 0;
    visibility: hidden;
    position: fixed;
        width: 3rem;
        height: 3rem;
        z-index: $zindex-fixed;
        right: 1rem;
        bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
  animation: movebtn 6s ease-in-out infinite;
  transition: all .5s ease-in-out;
  background-color:$secondary;
  color: white;
  border-radius: 100%;
  box-shadow: $box-shadow;
    &.show{
        opacity: 1;
        visibility: visible;
    }
}

@keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(10px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-10px);
    }
    100%{
      transform: translateY(0px);
    }
  }