///
/// Custom template typography
/// 
/// 
body{
    letter-spacing: $letter-spacing-body;
    -webkit-font-feature-settings:"kern";
    -moz-font-feature-settings:"kern";
    font-feature-settings:"kern";
}
body,html{
    height: 100%;
}
#__next{
    height: 100%;
}
.display-1,.display-2,.display-3,.display-4,.display-5,.display-6{
    letter-spacing: $letter-spacing-display;
}
.font-serif{
    font-family: var(--#{$prefix}font-serif)!important;
    font-weight: 400;
}
*{
    outline: 0!important;
    &:focus{
        outline: 0!important;
    }
}