.nloader{
    --#{$prefix}bg-opacity:1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    background-color: rgba(var(--#{$prefix}body-bg-rgb),var(--#{$prefix}bg-opacity));
    opacity: 0;
    visibility: hidden;
    .spinner-border{
        width: 4rem;
        height: 4rem;
    }
}
.nprogress-busy{
.nloader{
    opacity: 1;
    visibility: visible;
    transition:all .25s;
}
}