.breadcrumb-item{
    &::before{
        font-family: 'Material Symbols Rounded';
        vertical-align: middle;
        float: none;
    }
}

.text-center{
    .breadcrumb{
        justify-content: center;
    }
}
.text-white{
    .breadcrumb-item{
        &.active{
            color: rgba($white,.6);
        }
        &::before{
            opacity: .4;
        }
        a{
            color: rgba($white,.75);
            &:hover{
                color: $white;
            }
        }
    }
}