.swiper-prev,.swiper-next{
 &.swiper-button-disabled{
    opacity:.5;
    pointer-events: none;
 }   
}
.swiper-custom{
    .swiper{
        position: relative;
        overflow: visible;
        padding-top:1rem;
        padding-bottom: 1rem;
    }
}
.swiper-pagination{
    position: relative;
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .swiper-pagination-bullet{
        width: 12px;
        height: 12px;
        border-radius: 12px;
        transform: scale(.5);
        transition: all .2s;
        background-color: tint-color($primary,75%);
        opacity: 1;
        display: block;
        transform-origin: center;
        &:not(:last-child){
            margin-right: 6px;
        }
        &.swiper-pagination-bullet-active{
            transform: none;
            background-color:$primary;
        }
    }
}